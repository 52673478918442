<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import axios from "axios";

let user = JSON.parse(localStorage.getItem("user"));
let token = user.token;

export default {
  page: {
    title: "Döviz Ekle",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Döviz Ekle",
      items: [
        {
          text: "RTS",
          href: "/",
        },
        {
          text: "Döviz Yönetimi",
          href: "/exchange",
        },
        {
          text: "Döviz Ekle",
          active: true,
        },
      ],
      isButton: false,
      values: {
        code: "",
        name: "",
        total: "",
        symbol: "",
      },
    };
  },
  methods: {
    onSubmit() {
      this.isButton = true;
      const api_url = process.env.VUE_APP_BASEURL + "/exchange";
      axios
        .post(api_url, this.values, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          this.$router.push("/exchange");
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.isButton = false;
        })
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row justify-content-center">
      <div class="col-12 col-md-6">
        <div class="card mt-4">
          <div class="card-body">
            <form @submit.prevent="onSubmit">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group mb-3">
                    <label for="code">Kodu</label>
                    <input
                      v-model="values.code"
                      type="text"
                      class="form-control"
                      id="code"
                    />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group mb-3">
                    <label for="name">Adı</label>
                    <input
                      v-model="values.name"
                      type="text"
                      class="form-control"
                      id="name"
                    />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group mb-3">
                    <label for="total">Tutar</label>
                    <input
                      v-model="values.total"
                      type="text"
                      class="form-control"
                      id="total"
                    />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group mb-3">
                    <label for="symbol">Sembol</label>
                    <input
                      v-model="values.symbol"
                      type="text"
                      class="form-control"
                      id="symbol"
                    />
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-end align-items-center">
                <button type="submit" class="btn btn-primary px-4" :disabled="isButton">Kaydet</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
